import {Component, Input, OnInit} from '@angular/core';
import {NotificationDto} from "../../../../api/api";
import {NavigationService} from "../../../../services/navigation.service";

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.css']
})
export class NotificationViewComponent implements OnInit {

  type = 0;
  dto: any;
  get notification(): NotificationDto {
    return this._notification;
  }

  @Input() set notification(value: NotificationDto) {
    this._notification = value;
    if (this.notification.data) {
      this.dto = JSON.parse(value.data ?? '');
      console.log(this.dto);
    }
  }

  private _notification!: NotificationDto;
  constructor(private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  navigateToPage() {
    this.navigationService.navigateByUrl('dashboard/reservations/detail/' + this.dto.Id);
  }
}
