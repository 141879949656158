import {Component, OnInit, Renderer2, ViewChild, ElementRef, Directive} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd, NavigationStart} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Subscription} from 'rxjs';
import {SidebarComponent} from '../sidebar/sidebar.component';
import {filter} from 'rxjs/operators';
import {NavigationService} from '../../services/navigation.service';
import {NotificationClient, NotificationDto} from "../../api/api";

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  private listTitles?: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton?: any;
  private sidebarVisible: boolean;
  private _router?: Subscription;
  public open = false;
  private notificationCount = 0;
  rows: NotificationDto[] = []
  constructor(location: Location,
              private navigationService: NavigationService,
              private notificationClient: NotificationClient,
              private renderer: Renderer2, private element: ElementRef, private router: Router) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  initHandlers() {

  }

  get getNotificationBadge(): number {
    return this.notificationCount;
  }

  ngOnInit() {
    this.initHandlers();
    this.listTitles = SidebarComponent.Routes?.filter(listTitle => listTitle);

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const $layer = document.getElementsByClassName('close-layer')[0];
        if ($layer) {
          $layer.remove();
        }
      });
    this.notificationClient.notification_GetUnReadNotificationCount().subscribe(p => {
      this.notificationCount = p.count ?? 0;
    });
    this.notificationClient.notification_GetNotifications(
      false,
      undefined,
      true,
      undefined,
      true,
      1,
      5,
      'createdDate',
    ).subscribe(p => {
      this.rows = p.data ?? [];
    })
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;

    } else {
      setTimeout(function () {
        body.classList.add('sidebar-mini');

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  isMobileMenu() {
    if (window.outerWidth < 991) {
      return false;
    }
    return true;
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
  }

  sidebarToggle() {
    // var toggleButton = this.toggleButton;
    // var body = document.getElementsByTagName('body')[0];
    if (!this.sidebarVisible) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    if (this.listTitles == undefined) {
      return;
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      const parent = this.listTitles[item];
      if (parent.path === titlee) {
        return parent.title;
      } else if (parent.children) {
        const children_from_url = titlee.split('/')[2];
        for (let current = 0; current < parent.children.length; current++) {
          if (parent.children[current].path === children_from_url) {
            return parent.children[current].title;
          }
        }
      }
    }
    return 'Dashboard';
  }

  getPath() {
    // console.log(this.location);
    return this.location.prepareExternalUrl(this.location.path());
  }

  viewNotifications() {
    this.navigationService.navigateByUrl('/dashboard/notifications');
  }

  markAllRead() {
    this.notificationClient.notification_ReadAllUnReadNotification().subscribe(p => {
      this.rows.forEach(p => p.isRead = true);
    });
  }
}
