import { Pipe, PipeTransform } from '@angular/core';
import {NotificationType, TemplateDeviceType} from "../enums/inventory-reason-type";

@Pipe({
  name: 'notificationType'
})
export class NotificationTypePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value != undefined) {
      switch (value as NotificationType) {
        case NotificationType.ReservationCreated:
          return 'Reservation Created'
        case NotificationType.ReservationCancelled:
          return 'Reservation Cancelled'
        case NotificationType.ReservationConfirmed:
          return 'Reservation Confirmed'
        case NotificationType.ReservationPayed:
          return 'Reservation Payed'
      }
    }
    return '';
  }

}
