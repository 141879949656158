export enum TemplateDeviceType
{
  Sms,
  Email,
}
export enum TemplateType
{
  Pending = 1,
  Confirmed,
  Arrived,
  Left,
  NoShow,
  Cancelled,
  Payed
}
export enum NotificationType
{
  ReservationCreated = 1,
  ReservationCancelled,
  ReservationConfirmed,
  ReservationPayed
}
