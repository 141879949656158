import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import { of } from "rxjs";

@Pipe({
  name: 'appDate'
})
export class AppDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }
  transform(value: string | undefined | Date, isUtc = false): string {
    if (value) {
      if (this.isString(value)) {
        if (typeof value === 'string') {
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          if (isSafari) {
            value = value.replace(' ', 'T');
          }
          if (!value.includes('+')) {
            if (!value.endsWith('Z')) {
              value = value + 'Z';
            }
          }

        }
      }
      if (!isUtc) {
        const utc = new Date(value).toUTCString();
        const result = this.datePipe.transform(utc, 'medium');
        if (result == null) {
          return '';
        }
        return result;
      }
      else {
        const dateValue = new Date(value);

        const dateWithNoTimezone = new Date(
          dateValue.getUTCFullYear(),
          dateValue.getUTCMonth(),
          dateValue.getUTCDate(),
          dateValue.getUTCHours(),
          dateValue.getUTCMinutes(),
          dateValue.getUTCSeconds()
        );
        const result = this.datePipe.transform(dateWithNoTimezone, 'medium');
        if (result == null) {
          return '';
        }
        return result;
      }
    }
    return '';
  }
  isString(x: any): boolean {
    return Object.prototype.toString.call(x) === '[object String]';
  }

}
