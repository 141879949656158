<ng-container *ngIf="notification">
  <div class="row no-margins cursor" (click)="navigateToPage()">
    <div class="col-sm">
      <ng-container *ngIf="type == 0">
        <div class="row" [class.un-read]="!notification.isRead">
          <div class="col-sm-5 center-child">
            <div class="row">
              <div class="col-sm text-center">
                <i class="fas fa fa-book text-success" style="font-size: 2rem"></i>
              </div>
            </div>
            <div class="row">
              <div class="col-sm text-center">
                {{notification.createdDate | date: 'mediumDate'}}
              </div>
            </div>
          </div>
          <div class="col-sm-7 center-child">
            <div class="row">
              <div class="col-sm">
                <span [innerHtml]="dto.Status | templateTypeHtml"></span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <span class="font-weight-bold">{{dto.Date | date: 'mediumDate'}}</span> at <span
                class="font-weight-bold">{{dto.Time}}</span> for
                <span class="font-weight-bold">{{dto.TotalPeople}}p</span>
                <br>
                <span class="font-weight-bold">{{dto.CustomerName}}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
