import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {AlertService} from '../services/alert.service';
import {Router} from '@angular/router';
import {ErrorHandlerService} from '../services/error-handler.service';
import {from, Observable, throwError} from 'rxjs';
import {mergeMap, catchError, map} from 'rxjs/operators';
import {ResponseErrorModel} from '../models/response-error-model';
import {EndpointFactoryService} from '../services/endpoint-factory.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private endpointFactoryService: EndpointFactoryService,
    private authService: AuthService) {
  }

  private isRefreshing = false;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (req.responseType != 'blob' && req.headers.get('Authorization') === null && req.headers.get('Content-Type') === null) {
    //   const headers = this.endpointFactoryService.getRequestHeaders().headers;
    //   req = req.clone({headers});
    // } else if (req.headers.get('Authorization') == null) {
    //   const headers = this.endpointFactoryService.getRequestHeaders().headers;
    //   const newHeader = req.headers.set('Authorization', headers.get('Authorization') ?? []);
    //   req = req.clone({headers: newHeader});
    // }
    return next.handle(req)
      .pipe(map(event => {
        if (event instanceof HttpResponse) {
          if (event.body && event.body.message) {
            event = event.clone({body: this.resolveError(event.body)});
          }
          if (event.body && event.body.success === false) {
            throw new HttpErrorResponse({
              status: 400,
              error: event.body
            });
          }
        }
        return event;
      }))
      .pipe(catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(() => error);
      }));
  }

  private resolveError(body: any): any {
    return {
      response: null,
      success: body.success,
      error: this.CustomMessageResponse(body.message)
    };
  }


  CustomMessageResponse(msg: string): ResponseErrorModel {
    const data: ResponseErrorModel = new ResponseErrorModel();
    data.errors = [];
    data.message = msg;
    return data;
  }

  CustomNotOnlineResponse(): ResponseErrorModel {
    const data: ResponseErrorModel = new ResponseErrorModel();
    data.message = 'Token Expired';
    return data;
  }

}
