export class UserSettingKeys {
  public static readonly StockMinimumLevel = "StockMinimumLevel";
  public static readonly UniformChangeInterval = "UniformChangeInterval";

  public static readonly All = [
    UserSettingKeys.StockMinimumLevel,
    UserSettingKeys.UniformChangeInterval
  ];
}

export class GlobalUserSettingValues {
  public static CurrencyPrefix = '';
  public static DateFormat = 'dd/MM/YYYY HH:mm:ss';
}
